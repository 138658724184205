import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//import { useNavigate } from "react-router-dom";
//import { Link } from "react-router-dom";

import MKBox from "components/MKBox";

import MKTypography from "components/MKTypography";
import ChangelogJSON from "../../ChangeLog.json";

import Container from "@mui/material/Container";
import ChangelogList from "./components/ChangelogList";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

const Tables = () => {
  const bgImage = "";

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <MKBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MKBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6} lg={3}>
                        <MKTypography variant="h6" sx={{ fontSize: "24px" }} color="white">
                          Project List
                        </MKTypography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}></Grid>
                    </Grid>
                  </MKBox>

                  <MKBox pt={3}>{<ChangelogList Wikidata={ChangelogJSON} />}</MKBox>
                </Card>
              </Grid>
            </Grid>
          </MKBox>
        </Container>
      </MKBox>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default Tables;
