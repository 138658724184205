import React from "react";
import MDBox from "components/MKBox"; // Assuming MDBox is imported from somewhere
import PropTypes from "prop-types";
import MDBadge from "components/MKBadge";
// Component to render individual changelog items
const ChangelogItem = ({ item }) => {
  var BadgeClass = "";
  switch (item.type) {
    case "Fixed":
      BadgeClass = "error";
      break;
    case "Added":
      BadgeClass = "success";
      break;
    case "Changed":
      BadgeClass = "info";
      break;
    case "Removed":
      BadgeClass = "error";
      break;
    case "Deprecated":
      BadgeClass = "warning";
      break;
    case "Obsolete":
      BadgeClass = "error";
      break;
    case "Security":
      BadgeClass = "warning";
      break;
    default:
      BadgeClass = "success";
      break;
  }

  return (
    <MDBox
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        alignItems: "start",
        gap: "0.5rem",
        mb: "1rem",
      }}
    >
      <MDBadge
        badgeContent={item.type}
        size="sm"
        variant="contained"
        circular
        sx={{ height: "1.5rem", display: "flex", alignItems: "center", justifyContent: "center" }}
        color={BadgeClass}
      />
      <MDBox>
        {item.items.map((ele, key) => (
          <MDBox key={key} sx={{ mb: "0.5rem" }}>
            <MDBox sx={{ fontSize: "large", fontWeight: "bold" }}>{ele.title}</MDBox>
            <MDBox sx={{ fontSize: "small", color: "text.secondary" }}>{ele.description}</MDBox>
          </MDBox>
        ))}
      </MDBox>
    </MDBox>
  );
};

ChangelogItem.propTypes = {
  item: PropTypes.array.isRequired,
};
// Component to render each changelog entry
const ChangelogEntry = ({ entry }) => {
  return (
    <div>
      <h2>
        {entry[1].date} - Version {entry[1].version}
      </h2>
      {entry[1].changes.map((item, index) => (
        <ChangelogItem key={index} item={item} />
      ))}
    </div>
  );
};
ChangelogEntry.propTypes = {
  entry: PropTypes.array.isRequired,
};
// Main component to render the entire changelog
const ChangelogList = ({ Wikidata }) => {
  if (!Wikidata || !Wikidata.changelog) return null;
  //console.log(Object.entries(Wikidata.changelog));
  return (
    <div>
      {Object.entries(Wikidata.changelog).map((entry, index) => (
        <ChangelogEntry key={index} entry={entry} />
      ))}
    </div>
  );
};

ChangelogList.propTypes = {
  Wikidata: PropTypes.object.isRequired,
};
export default ChangelogList;
