import { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom

export const DataContext = createContext();
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

// Function to set a cookie
const setCookie = (name, value, days) => {
  let cookieString = `${name}=${value};path=/;`;

  if (days !== undefined && days !== null) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()};`;
    cookieString += expires;
  }

  document.cookie = cookieString;
};
export const DataProvider = ({ children }) => {
  const [OC, setOC] = useState({});
  const [JobCheckLists, setJobCheckLists] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOC = async (id) => {
    const fetchedProducts = await fetchOCdatabase(id);
    setOC(fetchedProducts);
    return fetchedProducts;
  };

  const fetchJobQC = async (id) => {
    const fetchedProducts = await fetchJobQCdatabase(id);
    setJobCheckLists(fetchedProducts);
    return fetchedProducts;
  };
  const { id } = useParams();
  // Fetch Job QC based on URL parameter when the component mounts
  useEffect(() => {
    let jobId = id;
    if (!jobId) {
      jobId = getCookie("jobId"); // Get ID from cookies if not present in URL
    } else {
      setCookie("jobId", jobId); // Set the cookie if ID is present in URL
    }

    if (jobId) {
      fetchJobQC(jobId).finally(() => setLoading(false));
    } else {
      setLoading(false); // No ID available, so stop loading
    }
  }, []);

  const fetchJobQCdatabase = async (id) => {
    const url = `https://waltekltd.org/Forms/Get_Data_secure.php`;
    const postData = new URLSearchParams();

    postData.append(
      "SQL",
      "SELECT OC_ID,OC_Job, Check_Date,Elevation_Num,Instance,SeriesDescription,  Building_Area,  Building_Side,	 Building_Level,RO_Shops, RO_Actual, Detail_JSON,  ContainsRejected,  Check_Author, Check_Series,  Check_Chat, Check_Selected_Contacts, SecKey, Submitted,employeeU FROM `Opening_CheckList` LEFT JOIN `SeriesRecords` ON SeriesRecords.id =Opening_CheckList.Check_Series JOIN `employeerecords` ON employeerecords.id =Opening_CheckList.Check_Author   WHERE `OC_Job` = " +
        id +
        " AND `Submitted` <> 0"
    );

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return [{}];
    }
  };

  const fetchOCdatabase = async (id) => {
    const url = `https://waltekltd.org/Forms/Get_Data_secure.php`;
    const postData = new URLSearchParams();

    postData.append(
      "SQL",
      "SELECT OC_ID, OC_Job, Check_Date, Elevation_Num, Instance, SeriesDescription, JobName, Building_Area, Building_Side, Building_Level, RO_Shops, RO_Actual, Detail_JSON, ContainsRejected, authorEmployee.employeeU AS AuthorEmployeeU, Check_Author, Check_Series, authorEmployee.Email,Check_Chat, Check_Selected_Contacts, SecKey, pmEmployee.Email AS Waltek_PM_Email, Submitted, pmEmployee.employeeU AS Waltek_PM_Name FROM `Opening_CheckList` JOIN Job_List ON Job_List.ID = Opening_CheckList.OC_Job LEFT JOIN `SeriesRecords` ON SeriesRecords.id = Opening_CheckList.Check_Series JOIN `employeerecords` AS authorEmployee ON authorEmployee.id = Opening_CheckList.Check_Author LEFT JOIN `employeerecords` AS pmEmployee ON pmEmployee.id = Job_List.Waltek_PM WHERE `OC_ID` = " +
        id
    );

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return [{}];
    }
  };

  return (
    <DataContext.Provider
      value={{ fetchOC, OC, fetchJobQC, JobCheckLists, loading, setCookie, getCookie }}
    >
      {children}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
