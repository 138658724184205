import { useState, useEffect, useContext, useCallback, useRef } from "react";

// prop-types is a library for type checking of props
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
// @mui material components

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
//import MKInput from "components/MKInput";
import MKTextArea from "components/MKTextArea";
//import Slide from "@mui/material/Slide";
//import { Link } from "react-router-dom";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import ChatWindow from "../../../../pages/ProductDetail/components/Chat";
//import MKAlert from "components/MKAlert";
//import MKButton from "components/MKButton";
//import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { DataContext } from "../../../../context";
import {
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

// Material Kit 2 React base styles
//import colors from "assets/theme/base/colors";

function View({ title, ...rest }) {
  const { fetchOC, OC, setCookie, getCookie } = useContext(DataContext); //add projects
  const chatWindowRef = useRef();
  const [selectedJob, setSelectedJob] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [Detail_JSON, setDetail_JSON] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [ContainsRejected, setContainsRejected] = useState(false);
  const [Check_Author, setCheck_Author] = useState(false);
  const [OC_ID, setOC_ID] = useState("");
  // const [OC_Job, setOC_Job] = useState("");
  const [Check_Date, setCheck_Date] = useState("");
  const [Elevation_Num, setElevation_Num] = useState("");
  const [Instance, setInstance] = useState("");
  const [Building_Area, setBuilding_Area] = useState("");
  const [Building_Side, setBuilding_Side] = useState("");
  const [Building_Level, setBuilding_Level] = useState("");
  const [RO_Shops, setRO_Shops] = useState("");
  const [RO_Actual, setRO_Actual] = useState("");
  const [Chat, setChat] = useState([]);
  const [secureKey, setsecureKey] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [PrimaryCon, setPrimaryCon] = useState("");
  const [CCCon, setCCCon] = useState({});
  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };
  const StatChange = (val) => {
    if (chatWindowRef.current && val[0] == "Corrected") {
      chatWindowRef.current.handleSubmit("", `Detail ${val[1]} has been marked as Corrected`);
      SaveCorrectedDetail(Detail_JSON);
    }
  };
  const key = getQueryParams().get("key");
  const LocationOptions = [
    { label: "Head" },
    { label: "Intermediate" },
    { label: "Sill" },
    { label: "Jamb" },
  ];
  const StatusOptions = [
    { label: "Rejected", key: "Rejected" },
    { label: "Corrected", key: "Corrected" },
  ];

  useEffect(() => {
    // Function to fetch data from the database
    async function fetchData() {
      const data = await fetchOC(title); // Assuming fetchOC returns the fetched data
      if (data && data[1]) {
        data[1].Detail_JSON = JSON.parse(data[1].Detail_JSON);
        data[1].Check_Chat = JSON.parse(data[1].Check_Chat);

        setSelectedJob(data[1].JobName);
        setOC_ID(data[1].OC_ID);
        //setOC_Job(data[1].OC_Job);
        setCheck_Date(data[1].Check_Date);
        setElevation_Num(data[1].Elevation_Num);
        setInstance(data[1].Instance);
        setBuilding_Area(data[1].Building_Area);
        setBuilding_Side(data[1].Building_Side);
        setBuilding_Level(data[1].Building_Level);
        setRO_Shops(data[1].RO_Shops);
        setChat(data[1].Check_Chat);
        setRO_Actual(data[1].RO_Actual);
        setDetail_JSON(data[1].Detail_JSON);
        setContainsRejected(data[1].ContainsRejected);
        setCheck_Author(data[1].AuthorEmployeeU);
        const Contact = JSON.parse(data[1].Check_Selected_Contacts);
        setPrimaryCon(Contact?.Primary);
        setCCCon(Contact?.Emails);
        if (data[1].SecKey == key || getCookie("User") == Contact.Primary) {
          setsecureKey(true);
          setCookie("User", Contact.Primary); //Set user to cross against other OCLS
        }
      }
    }

    fetchData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const SaveCorrectedDetail = async (detail) => {
    const url = `https://waltekltd.org/Forms/Update_Opening_Check.php`;
    const postData = new URLSearchParams();
    const data = {};

    data._QC_ID = OC_ID;
    data._Detail_JSON = detail;
    postData.append("action", "Detail_JSON"); //change to real reciepient

    const appendNestedData = (prefix, obj) => {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          const formKey = prefix ? `${prefix}[${key}]` : key;

          if (typeof value === "object" && value !== null) {
            appendNestedData(formKey, value);
          } else {
            postData.append(formKey, value);
          }
        }
      }
    };

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        if (typeof data[key] === "object" && data[key] !== null) {
          appendNestedData(`data[${key}]`, data[key]);
        } else {
          postData.append(`data[${key}]`, data[key]);
        }
      }
    }
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      ///alert message failed to send
      console.log(error);
      console.error("Error fetching or processing data:", error);
      return [{}];
    }
  };
  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    p: 2,
    overflow: "hidden",
  };

  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "90%",
    objectFit: "contain",
  };

  const handleOpen = (url) => {
    setImageUrl(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ImageModal = ({ open, handleClose, imageUrl }) => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Image Preview
          </Typography>
          <img src={imageUrl} alt="Modal content" style={imageStyle} />
          <Button
            onClick={handleClose}
            sx={{ mt: 2, color: "common.white" }}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </Box>
      </Modal>
    );
  };
  ImageModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    imageUrl: PropTypes.string,
  };
  const SentAvatar = ({ Contact }) => {
    return (
      <TableRow>
        <TableCell align="right">
          <Typography variant="body1">Primary</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body1" sx={{ fontSize: "small" }}>
            {Contact}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };
  SentAvatar.propTypes = {
    Contact: PropTypes.string,
  };

  const CCAvatars = ({ CC }) => {
    return (
      <TableRow>
        <TableCell align="right">
          <Typography variant="body1">CC Contacts</Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction="column" spacing={1}>
            {Object.entries(CC).map((email, index) => (
              <div key={index}>
                <Typography variant="body1" sx={{ fontSize: "small" }}>
                  {email[1]}
                </Typography>
              </div>
            ))}
          </Stack>
        </TableCell>
      </TableRow>
    );
  };
  CCAvatars.propTypes = {
    CC: PropTypes.object,
  };
  const handleStatusChange = useCallback(
    (event, index) => {
      const newNotes = event.target.textContent;

      setDetail_JSON((prev) => {
        const updatedDetails = [...prev.Main.Details];
        setNewStatus([newNotes, updatedDetails[index].Detail]);
        updatedDetails[index].Status = newNotes;
        return { ...prev, Main: { Details: updatedDetails } };
      });
    },
    [setDetail_JSON]
  );
  useEffect(() => {
    if (newStatus !== null) {
      StatChange(newStatus);
    }
  }, [newStatus]);

  const getColor = (status) => {
    switch (status) {
      case "Rejected":
        return "error.main"; // or any color you want

      case "Corrected":
        return "success.main"; // or any color you want
      default:
        return "info.main"; // default color
    }
  };

  return (
    <MKBox
      width="100%"
      position="relative"
      borderRadius="xl"
      shadow="lg"
      mb={12}
      sx={{ overflow: "hidden" }}
      {...rest}
    >
      <MKBox
        px={3}
        sx={{
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      ></MKBox>
      <MKBox display="block" sx={{ px: { xs: 0, sm: 1, md: 2 } }}>
        <MKBox width="100%" sx={{ px: { xs: 0, sm: 1, md: 2 } }}>
          <Box id="contentpage" className="contentpage" sx={{ px: { xs: 0, sm: 1, md: 2 } }}>
            <Box className="Content-Title">
              <Typography variant="h6" className="Content-Text">
                <b>Opening Check-List</b>
              </Typography>
            </Box>

            <Box
              className="Headbar2"
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ color: "common.white" }}
                onClick={() =>
                  window.open(
                    `https://waltekltd.org/Reports/pdf/Generate_Opening_Checklist.php?id=${OC_ID}`
                  )
                }
              >
                PDF
              </Button>
            </Box>
            <Box className="content" id="container2">
              <Box className="modal-content" id="Open_Check_Items2">
                <Box className="modal-body" id="Open_Check_Items5">
                  <form id="ModalFormOpen_Check_Items" onSubmit={handleSubmit}>
                    <Box className="form-group" sx={{ mb: 2 }}>
                      <FormControl sx={{ mb: 2 }}>
                        <TextField
                          name="Check_Date"
                          label="Created Date"
                          value={Check_Date}
                          disabled
                          sx={{ textAlign: "center" }}
                        />
                      </FormControl>

                      <FormControl sx={{ mb: 2 }}>
                        <TextField
                          name="Open_OC_ID"
                          label="OCL#"
                          value={"OCL-" + OC_ID}
                          disabled
                          sx={{ textAlign: "center" }}
                        />
                      </FormControl>
                      <FormControl sx={{ mb: 2 }}>
                        <TextField
                          name="Open_Check_Author"
                          label="Check List Author"
                          value={Check_Author} //get emloyee name
                          disabled
                          sx={{ textAlign: "center" }}
                        />
                      </FormControl>
                      <FormControl sx={{ mb: 2 }}>
                        <TextField
                          name="Open_selectedJob"
                          label="Project Name"
                          value={selectedJob}
                          disabled
                          sx={{ textAlign: "center" }}
                        />
                      </FormControl>
                    </Box>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="right">
                              <Typography variant="body1">Elevation</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <TextField name="Elevation_Num" value={Elevation_Num} disabled />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="right">
                              <Typography variant="body1">Instance</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <TextField name="Instance" value={Instance} disabled />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="right">
                              <Typography variant="body1">Building Area</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <TextField name="Building_Area" value={Building_Area} disabled />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="right">
                              <Typography variant="body1">Building Side</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <TextField name="Building_Side" value={Building_Side} disabled />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="right">
                              <Typography variant="body1">Building Level</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <TextField name="Building_Level" value={Building_Level} disabled />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="right">
                              <Typography variant="body1">Rough Opening Per Shops</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <TextField name="RO_Shop" value={RO_Shops} disabled />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="right">
                              <Typography variant="body1">Rough Opening Actual</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <TextField name="RO_Actual" value={RO_Actual} disabled />
                            </TableCell>
                          </TableRow>
                          <SentAvatar Contact={PrimaryCon} />
                          <CCAvatars CC={CCCon} />
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer sx={{ minHeight: "15rem" }} component={Paper}>
                      <Table id="Open_Check_Item_Table">
                        <TableHead sx={{ display: "contents" }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                minWidth: "5rem",
                                px: { xs: 0, sm: 1, md: 2 },
                              }}
                            >
                              Detail #
                            </TableCell>
                            <TableCell
                              sx={{
                                px: { xs: "0 !important", sm: "0 !important", md: "0 !important" },
                              }}
                              align="right"
                            >
                              Status
                            </TableCell>
                            <TableCell align="right">Location</TableCell>
                            <TableCell align="right">Picture</TableCell>
                            <TableCell align="right">Notes</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Detail_JSON?.Main &&
                            Object.entries(Detail_JSON.Main.Details).map(([key, value]) => (
                              <TableRow key={key}>
                                <TableCell
                                  sx={{
                                    width: "auto",
                                    maxWidth: "none",
                                    whiteSpace: "nowrap",
                                    px: { xs: 0, sm: 1, md: 2 },
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      marginBottom: 2,
                                      width: "100%",
                                    }}
                                  >
                                    <TextField
                                      name={`Detail#${key}`}
                                      label={`Detail# ${key}`}
                                      value={value.Detail}
                                      sx={{ textAlign: "center" }}
                                      fullWidth
                                      disabled
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    px: { xs: 0, sm: 0, md: 2 },
                                  }}
                                >
                                  <FormControl sx={{ marginBottom: 2, width: "100%" }} fullWidth>
                                    <Autocomplete
                                      disablePortal
                                      fullWidth
                                      name={`Status_${key}`}
                                      id="combo-box-demo"
                                      value={value.Status ? { label: value.Status } : null}
                                      options={StatusOptions}
                                      isOptionEqualToValue={(option, value) =>
                                        option.label === value.label
                                      }
                                      onChange={(event) => {
                                        handleStatusChange(event, key);
                                      }}
                                      renderInput={(params) => {
                                        const color = getColor(value.Status);
                                        return (
                                          <TextField
                                            {...params}
                                            label={`Status ${key}`}
                                            sx={{
                                              ".MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                  borderColor: color,
                                                },
                                                "&:hover fieldset": {
                                                  borderColor: color,
                                                },
                                                "&.Mui-focused fieldset": {
                                                  borderColor: color,
                                                },
                                              },
                                              ".MuiAutocomplete-inputRoot": {
                                                color: color,
                                              },
                                            }}
                                          />
                                        );
                                      }}
                                      sx={{
                                        ".MuiAutocomplete-popupIndicator": {
                                          color: getColor(value.Status),
                                        },
                                        ".MuiAutocomplete-clearIndicator": {
                                          color: getColor(value.Status),
                                        },
                                      }}
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    px: { xs: 0, sm: 0, md: 2 },
                                  }}
                                >
                                  <FormControl sx={{ marginBottom: 2, width: "100%" }} fullWidth>
                                    <Autocomplete
                                      disablePortal
                                      id="combo-box-demo"
                                      value={{ label: value.Location }}
                                      options={LocationOptions}
                                      disabled
                                      fullWidth
                                      isOptionEqualToValue={(option, value) => {
                                        return option.label == value.label;
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} label="Location" />
                                      )}
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    px: { xs: 0, sm: 0, md: 2 },
                                  }}
                                >
                                  <FormControl sx={{ marginBottom: 2 }} fullWidth>
                                    {value.PictureUpload !== "" && (
                                      <MKButton
                                        component="button" // Use Link component
                                        // Specify the route
                                        color="info"
                                        onClick={() => {
                                          handleOpen(value.PictureUpload);
                                        }}
                                        fontWeight="regular"
                                        sx={{ width: "6rem" }}
                                      >
                                        View Picture
                                      </MKButton>
                                    )}
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <FormControl sx={{ marginBottom: 2 }} fullWidth>
                                    <MKTextArea
                                      label="Notes"
                                      multiline
                                      disabled
                                      value={value.Notes}
                                      sx={{ minWidth: "10rem" }}
                                    />
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <ImageModal
                      id="ChecklistPhoto"
                      open={open}
                      handleClose={handleClose}
                      imageUrl={imageUrl}
                    />
                  </form>
                </Box>
              </Box>
            </Box>
          </Box>
        </MKBox>
      </MKBox>
      <MKBox display="block" p={3}>
        <MKBox width="100%" sx={{ px: { xs: 0, sm: 1, md: 2 } }}>
          <MKBox bgColor="grey-100" width="100%" borderRadius="xl" sx={{ overflowX: "hidden" }}>
            <Stack direction="column" alignItems="flex-start" spacing={1}>
              <ChatWindow
                ref={chatWindowRef}
                Chatdata={Chat}
                OclData={OC}
                Primary={secureKey}
              ></ChatWindow>
            </Stack>
          </MKBox>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the View
View.propTypes = {
  title: PropTypes.string,
};

export default View;
